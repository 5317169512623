import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >

    <div>
      <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
        <p className="text-sm text-designColor tracking-[4px]">2023 - Present</p>
        <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
      </div>
      <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
        <ResumeCard
          title="Front_End Developer"
          subTitle="Fiverr - (2023 - Present)"
          result="Regular"
          des="HTML5, CSS3, JavaScript and React,NextJS : I'm proficient in the latest technologies to ensure your website is cutting-edge and efficient."
        />
        <ResumeCard
          title="Figma to React/NextJS"
          subTitle="Fiverr - (2023 - Present)"
          result="Regular"
          des="I'm an expert in Figma/PSD/XD/AI Design for HTML, CSS, Bootstrap Responsive Websites, React js Websites, and Frontend Websites. ."
        />
        <ResumeCard
          title="Front-End Developer"
          subTitle="Upwork - (2023 - Present)"
          result="Upwork"
          des="The Upwork economy has grown strongly over recent years, having transformed itself from a producer and innovation-based economy."
        />
      </div>
    </div>
    </motion.div>
  );
};

export default Experience;
