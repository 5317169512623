import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaFacebookF, FaLinkedinIn, FaReact, FaInstagram,FaNodeJs } from "react-icons/fa";
import { SiTailwindcss, SiFigma, SiNextdotjs } from "react-icons/si";

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Front-end Developer.", "React Developer.", "Youtuber."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col md:gap-20 gap-4">
      <div className="flex flex-col gap-2">
        <h4 className=" md:text-lg text-xs font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="md:text-6xl text-3xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">MH.MOTIN</span>
        </h1>
        <h2 className="md:text-4xl text-base font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="md:text-base text-sm font-bodyFont leading-6 tracking-wide">
          passionate front-end web developer with 1 years of experience in crafting stunning and user-friendly websites. Whether you're an individual, a startup, or a business, I'm here to bring your digital vision to life.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
            <a href="https://www.facebook.com/mahmudulhasanmotin3" target="_blank"><FaFacebookF /></a>
            </span>
            <span className="bannerIcon">
            <a href="https://www.instagram.com/mahmudulhasanmotin/" target="_blank"><FaInstagram /></a>
            </span>
            <span className="bannerIcon">
            <a href="https://www.linkedin.com/in/mahmudulhasanmotin/" target="_blank"><FaLinkedinIn /></a>
            </span>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
                <a href="https://react.dev/"target="_blank" ><FaReact /></a>
            </span>
            <span className="bannerIcon">
                <a href="https://nextjs.org/" target="_blank"><SiNextdotjs /></a>
            </span>
            <span className="bannerIcon">
                <a href="https://nodejs.org/en" target="_blank"><FaNodeJs /></a>
            </span>
            <span className="bannerIcon">
                <a href="https://tailwindcss.com/" target="_blank"><SiTailwindcss /></a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftBanner