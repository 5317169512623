import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree, projectFour, projectFive, projectSix } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <a href="https://superado-landing-main.vercel.app/" target="_blank">
        <ProjectsCard
          title="Superado Landing"
          des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          src={projectOne}
        />
        </a>
        <a href="https://e-commerce-best-pages.vercel.app/" target="_blank">
          <ProjectsCard
            title="E-commerce Website"
            des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
            src={projectTwo}
          />
        </a>
        <a href="https://smart-ticketing-eight.vercel.app/" target="_blank">
          <ProjectsCard
            title="Smart e-Ticketing"
            des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
            src={projectThree}
          />
        </a>
        <a href="https://creative-agency-webpage.vercel.app/" target="_blank">
          <ProjectsCard
            title="Creative Agency"
            des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
            src={projectFour}
          />
        </a>
        <a href="https://portfolio-type-project.vercel.app/" target="_blank">
          <ProjectsCard
            title="Portfolio Website"
            des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
            src={projectFive}
          />
        </a>
        <a href="https://digital-marketing-agency-website.vercel.app/" target="_blank">
          <ProjectsCard
            title="Digital Marketing Website"
            des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
            src={projectSix}
          />
        </a>
      </div>
    </section>
  );
}

export default Projects