import React from 'react'
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col md:gap-4 gap-1">
        <h3 className="text-3xl font-bold text-center text-white">MH.MOTIN</h3>
        <p className="text-lg text-center font-medium text-gray-400">
          Font-End Developer
        </p>
        <p className="text-base text-center text-gray-400 tracking-wide">
        with 1 years of experience in crafting stunning and user-friendly websites. Whether you're an individual, a startup, or a business, I'm here to bring your digital vision to life.
        </p>
        <p className="text-base text-center text-gray-400  gap-2">
          Phone: <span className="text-lightText text-center">+880 19106-33227</span>
        </p>
        <p className="text-base text-center  text-gray-400 gap-2">
          Email: <span className="text-lightText text-center">mahmudulhasanmotin@gmail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont text-center mb-4">Find me in</h2>
        <div className="flex justify-center items-center gap-4">
          <span className="bannerIcon">
          <a href="https://www.facebook.com/mahmudulhasanmotin3" target="_blank"><FaFacebookF /></a>
          </span>
          <span className="bannerIcon">
          <a href="https://www.instagram.com/mahmudulhasanmotin/" target="_blank"><FaInstagram /></a>
          </span>
          <span className="bannerIcon">
          <a href="https://www.linkedin.com/in/mahmudulhasanmotin/" target="_blank"><FaLinkedinIn /></a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default ContactLeft